<template>
  <div class="flex items-center info-plan" v-if="accPlanData.id !== 0">
    <!--vs-button
      @click="popupActive=true"
      active.sync="popupActive"
      class="flex-inline sm:hidden md:hidden lg:hidden xl:hidden mr-4"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-package"
      size="small"
    ></vs-button-->
    <vs-button
      v-if="$acl.check('master')"
      @click="popupMasterActive = true"
      active.sync="popupMasterActive"
      class="flex-inline mr-4"
      color="primary"
      type="border"
      icon-pack="feather"
      icon="icon-shield"
      size="small"
    ></vs-button>

    <vs-button
      active.sync="popupActive"
      class="inline-flex mr-3"
      color="primary"
      type="border"
      icon-pack="feather"
      size="small"
      :to="{ name: 'settings' }"
    >
      <p
        class="hidden sm:hidden md:flex lg:flex xl:flex"
        v-if="
          accPlanData.startTrialDate &&
          accPlanData.paymentStatus === 0 &&
          !accPlanData.superlogicaId
        "
      >
        {{
          $t("TrialDaysLeft", [
            accPlanData.trialDays,
            accPlanData.trialDays > 1 ? $t("Days") : $t("Day"),
          ])
        }}
      </p>

      <p
        class="flex sm:flex md:hidden lg:hidden xl:hidden"
        v-if="
          accPlanData.startTrialDate &&
          accPlanData.paymentStatus === 0 &&
          !accPlanData.superlogicaId
        "
      >
        {{ accPlanData.trialDays }}
        {{ accPlanData.trialDays > 1 ? $t("Days") : $t("Day") }}
      </p>

      <strong v-else style="font-weight: 620 !important">
        {{ accPlanData.plan.name }}
      </strong>
    </vs-button>

    <!-- <a
      class="mr-1"
      target="_blank"
      href="https://api.whatsapp.com/send/?phone=5511976066398&text=Ol%C3%A1%2C+gostaria+de+saber+mais+informa%C3%A7%C3%B5es&app_absent=0"
    >
      <feather-icon icon="HelpCircleIcon" svgClasses="w-6 h-8" />
    </a> -->

    <vs-button
      class="inline-flex mr-3"
      color="primary"
      type="border"
      icon="question_mark"
      size="small"
      @click="openHelpPage"
    ></vs-button>

    <span v-if="false" class="hidden sm:hidden lg:inline-flex mr-3">
      <feather-icon
        class="mr-1"
        icon="MessageSquareIcon"
        svgClasses="h-4 w-4"
      ></feather-icon>
      <strong class="mr-1">{{ accPlanData.planParameters.numChat }}</strong>
      &nbsp; {{ $t("Of") }} {{ accPlanData.plan.planParameters.numChat }}
      {{
        accPlanData.plan.planParameters.numChat > 1 ? $t("Chats") : $t("Chat")
      }}
    </span>

    <span v-if="false" class="hidden sm:hidden lg:inline-flex mr-3">
      <feather-icon
        class="mr-1"
        icon="UsersIcon"
        svgClasses="h-4 w-4"
      ></feather-icon>
      <strong>{{ accPlanData.planParameters.numUsers }}</strong>
    </span>

    <!-- <vs-button
      class="hidden sm:hidden lg:inline-flex mr-2 pl-0"
      color="warning"
      type="flat"
      icon-pack="feather"
      icon="icon-alert-circle"
      size="small"
      v-if="accPlanData.trialDays"
    >{{$t('TrialDaysLeft',[accPlanData.trialDays,accPlanData.trialDays > 1 ? $t('Days'):$t('Day')])}}</vs-button> -->

    <vs-popup
      class="holamundo"
      :title="$t('YourDuoTalkAcc')"
      :active.sync="popupActive"
    >
      <div class="flex flex-wrap">
        <div class="w-full">
          <p class="flex items-center mb-4">
            <feather-icon
              icon="PackageIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2 w-1/2 md:w-1/3">
              {{ $t("YourPlanIs") }}
              <strong>{{ accPlanData.plan.name }}</strong>
            </span>
          </p>
          <p class="flex items-center mb-4">
            <feather-icon
              icon="MessageSquareIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">
              {{ $t("Has") }}
              <strong>{{ accPlanData.planParameters.numChat }}</strong>
              {{ $t("Of") }} {{ accPlanData.plan.planParameters.numChat }}
              {{
                accPlanData.plan.planParameters.numChat > 1
                  ? $t("Chats")
                  : $t("Chat")
              }}
            </span>
          </p>
          <p class="flex items-center mb-4">
            <feather-icon
              icon="SendIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">
              <strong>{{ accPlanData.planParameters.numLeads }}</strong>
              {{ $t("OnTotalOf") }}
              {{ accPlanData.plan.planParameters.numLeads }}
              {{ $t("Talks") }}
            </span>
          </p>
          <p class="flex items-center mb-4">
            <feather-icon
              icon="UsersIcon"
              svgClasses="h-5 w-5 text-primary stroke-current"
            />
            <span class="ml-2">
              {{ $t("With") }}
              <strong>{{ accPlanData.planParameters.numUsers }}</strong>
              {{
                accPlanData.planParameters.numUsers > 1
                  ? $t("Users")
                  : $t("User")
              }}
            </span>
          </p>
        </div>
      </div>
      <vs-button
        v-if="!isProPlan"
        @click="goToPlan()"
        :to="{ name: 'settings' }"
        class="w-40 w-full"
        color="success"
        type="filled"
        >{{ $t("MigrateNow") }}</vs-button
      >
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Editar Dados Sensiveis da Conta"
      :active.sync="popupMasterActive"
    >
      <div class="flex flex-wrap">
        <div class="vx-col w-full mt-4">
          <vs-input
            data-vv-validate-on="blur"
            v-validate="'required|min:3|max:30'"
            name="companyName"
            v-model="newAccName"
            class="w-full"
            :label="$t('CompanyName')"
            danger-text="1|2|3"
          />
          <span class="text-danger text-sm">{{
            errors.first("companyName")
          }}</span>
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">{{
            $t("CompanyIndustry")
          }}</label>
          <v-select
            v-model="newAccCompanyIndustry"
            :clearable="false"
            :options="segmentOptions"
            name="segment"
            class="w-full"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">{{ $t("Plan") }}</label>
          <v-select
            v-model="newAccPlan"
            :clearable="false"
            :options="plansOptions"
            name="plan"
            class="w-full"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <vs-input
            name="planEndTrial"
            v-model="newAccPlanEndTrialDays"
            class="w-full"
            label="Fim do trial"
          />
        </div>

        <div class="vx-col w-full mt-4">
          <vs-input
            name="plansuperlogicaid"
            v-model="currentAccPlanSuperlogicaId"
            class="w-full"
            label="ID Superlogica"
          />
        </div>

        <div class="w-full">
          <vs-row>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label class="vs-input--label">Bloquear Conta</label>
                <vs-switch color="#28c76f" v-model="currentAccLocked" />
              </div>
            </vs-col>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label class="vs-input--label">Duotalk IA</label>
                <vs-switch color="#28c76f" v-model="currentAiEnable" />
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label class="vs-input--label">Bloquear Download Excel</label>
                <vs-switch color="#28c76f" v-model="blockDownloadExcel" />
              </div>
            </vs-col>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label class="vs-input--label">Duotalk Assistente</label>
                <vs-switch color="#28c76f" v-model="currentAssistantEnable" />
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label class="vs-input--label"
                  >Bloquear Aba Contato Global</label
                >
                <vs-switch color="#28c76f" v-model="blockContactTabGeneral" />
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="6">
              <div class="mt-4">
                <label for="" class="vs-input--label"
                  >Disponibilizar Automações</label
                  >
                <vs-switch color="#28c76f" v-model="automationEnable" />
              </div>
            </vs-col>
          </vs-row>
        </div>

        <div class="vx-col w-full mt-4">
          <label for="" class="vs-input--label">Disparos por SMS</label>
          <vs-switch color="#28c76f" v-model="smsDispatchEnable" />
        </div>
        <div class="vx-col w-full mt-4" v-if="smsDispatchEnable">
          <label class="vs-input--label">Créditos de SMS</label>
          <div class="vs-input--label">
            <vs-input-number
              v-model="smsCredit"
              min="0"
              max="7000000"
              step="10000"
              style="max-width: 150px"
            />
          </div>
        </div>

        <div class="vx-col w-full mt-4">
          <vs-button
            @click="editAcc()"
            class="w-40 w-full"
            color="success"
            type="filled"
            >Editar</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    "v-select": vSelect,
  },
  data() {
    return {
      popupActive: false,
      currentAcc: false,
      popupMasterActive: false,
      currentAccLocked: false,
      currentAiEnable: false,
      currentAssistantEnable: false,
      smsDispatchEnable: false,
      automationEnable: false,
      smsCredit: 0,
      plansOptions: [],
      currentAccPlan: null,
      currentAccName: null,
      currentAccCompanyIndustry: null,
      currentAccPlanEndTrialDays: null,
      currentAccPlanSuperlogicaId: "",
      segmentOptions: [
        {
          label: "Automotivo",
          value: "automotivo",
        },
        {
          label: "Agência",
          value: "agencia",
        },
        {
          label: "Beleza",
          value: "beleza",
        },
        {
          label: "B2B",
          value: "b2b",
        },
        {
          label: "Imobiliária",
          value: "imobiliaria",
        },
        {
          label: "Produtos",
          value: "produtos",
        },
        {
          label: "Restaurante",
          value: "restaurante",
        },
        {
          label: "Saúde",
          value: "saude",
        },
        {
          label: "Serviços",
          value: "servicos",
        },
        {
          label: "Startup",
          value: "startup",
        },
        {
          label: "Outros",
          value: "outros",
        },
      ],
      blockDownloadExcel: false,
      blockContactTabGeneral: false,
    };
  },
  methods: {
    openHelpPage() {
      window.open(
        "https://api.whatsapp.com/send/?phone=5511976066398&text=Ol%C3%A1%2C+gostaria+de+saber+mais+informa%C3%A7%C3%B5es&app_absent=0"
      );
    },
    activatePopup() {
      this.popupActive = true;
    },
    goToPlan() {
      this.popupActive = false;
      this.$router.push("/settings").catch(() => {});
    },
    editAcc() {
      this.$http
        .put(`/g/acc/${this.$store.state.acc.current_acc.id}`, {
          plan: this.newAccPlan.value,
          name: this.newAccName,
          companyIndustry: this.newAccCompanyIndustry.value,
          planEndTrialDays: this.newAccPlanEndTrialDays,
          planSuperlogicaId: this.currentAccPlanSuperlogicaId,
          locked: this.currentAccLocked,
          aiEnable: this.currentAiEnable,
          assistantEnable: this.currentAssistantEnable,
          blockDownloadExcel: this.blockDownloadExcel,
          blockContactTabGeneral: this.blockContactTabGeneral,
          smsDispatchEnable: this.smsDispatchEnable,
          automationEnable: this.automationEnable,
          smsCredit: this.smsCredit,
        })
        .then(() => {
          window.location.replace("/");
        })
        .catch((error) => {
          if (typeof error.request !== "undefined")
            if (typeof error.request.response !== "undefined")
              error.message = JSON.parse(error.request.response).message;

          this.$vs.notify({
            time: 2500,
            title: this.$t("Error"),
            text: error.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
          });
        });
    },
    getPlans() {
      var self = this;
      //fetchGetPlans
      const payload = {
        product: "5e4c20ee2767d32b33eaa12e",
        whitelabel: this.$store.state.acc.current_acc.whitelabel,
        notify: this.$vs.notify,
      };
      this.$store
        .dispatch("auth/fetchGetPlans", payload)
        .then((result) => {
          for (var i = 0; i < result.data.data.length; i++) {
            if (result.data.data[i].listEditing)
              self.plansOptions.push({
                label: result.data.data[i].title,
                value: result.data.data[i]._id,
              });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: this.$t("Error"),
            text: error.message || error.data[0].msg,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
            position: "top-right",
            time: 4000,
          });
        });
    },
  },
  computed: {
    newAccPlan: {
      get: function () {
        return (
          this.currentAccPlan || {
            label: this.$store.state.acc.current_acc.accPlan.plan.name,
            value: this.$store.state.acc.current_acc.accPlan.plan.id,
          }
        );
      },
      set: function (value) {
        this.currentAccPlan = value;
      },
    },
    newAccName: {
      get: function () {
        return this.currentAccName || this.$store.state.acc.current_acc.name;
      },
      set: function (value) {
        this.currentAccName = value;
      },
    },
    newAccCompanyIndustry: {
      get: function () {
        return (
          this.currentAccCompanyIndustry ||
          this.$store.state.acc.current_acc.companyIndustry
        );
      },
      set: function (value) {
        this.currentAccCompanyIndustry = value;
      },
    },
    newAccPlanEndTrialDays: {
      get: function () {
        return (
          this.currentAccPlanEndTrialDays ||
          this.$store.state.acc.current_acc.accPlan.endTrialDate
        );
      },
      set: function (value) {
        this.currentAccPlanEndTrialDays = value;
      },
    },
    accPlanData() {
      return this.$store.state.acc.current_acc.accPlan;
    },
    isProPlan() {
      return this.accPlanData.plan.planParameters.numChatPage
        ? this.accPlanData.plan.planParameters.numChatPage > 0
        : false;
    },
  },
  watch: {
    popupMasterActive() {
      this.currentAccPlan = null;
      this.currentAccName = null;
      this.currentAccCompanyIndustry = null;
      this.currentAccPlanEndTrialDays = null;
      this.currentAccPlanSuperlogicaId =
        this.$store.state.acc.current_acc.accPlan.superlogicaId;
      this.currentAccLocked = this.$store.state.acc.current_acc.locked;
      this.currentAiEnable = this.$store.state.acc.current_acc.aiEnable;
      this.currentAssistantEnable =
        this.$store.state.acc.current_acc.assistantEnable;
      this.blockDownloadExcel =
        this.$store.state.acc.current_acc.blockDownloadExcel;
      this.blockContactTabGeneral =
        this.$store.state.acc.current_acc.blockContactTabGeneral;
      this.smsDispatchEnable =
        this.$store.state.acc.current_acc.smsDispatchEnable;
      this.smsCredit = this.$store.state.acc.current_acc.smsCredit;
      this.automationEnable =
        this.$store.state.acc.current_acc.automationEnable;
    },
    smsCredit() {
      if (parseInt(this.smsCredit) < 0) {
        this.smsCredit = 0;
      }
    },
  },
  mounted() {
    if (this.$acl.check("master")) this.getPlans();
  },
};
</script>

<style scoped>
.pro-btn {
  padding: 10px 21px 9px 21px !important;
}
.info-plan span {
  font-size: 0.8rem;
  vertical-align: text-bottom;
  color: #999;
}
</style>
